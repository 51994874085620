import "regenerator-runtime/runtime";

// script interface
import "../components/common_components/reset/index";
import "../components/common_components/normalize/index";
import "../components/common_components/header/index";
import "../components/common_components/footer/index";

import "../components/interface/variables/index";
import "../components/interface/button/index";
import "../components/interface/input/index";
import "../components/interface/size-label/index";

import "../components/module/modal_script/index";
import "../components/module/validation/index";
import "../components/module/form_action/index";
import "../components/module/catalog_items/index";
import "../components/module/catalog_slider/index";
import "../components/module/cart_item/index";
import "../components/module/blog_item/index";

import "../utils/customMask";

import "../components/pages/index/index";
